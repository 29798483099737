import {baseApiUrl} from './constants';
import { saveAs } from 'file-saver';

function getApi(url, body, method) {
  const request = new Request(`${baseApiUrl}/${url}`, {
    method: method || 'GET',
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'none'
    }),
  });
  return fetch(request)
    .then(response => response.text().then( text => ({ status: response.status, statusText: response.statusText, text })))
    .then(response => {
      let errorText = "";
      let object = {};
      try { object = JSON.parse(response.text); errorText = object?.message ?? response.statusText; } catch {}
      if (response.status < 200 || response.status >= 300) {
        throw new Error(errorText);
      }
      return object;
    });
}

function getFile(url, article, body, method) {
  const request = new Request(`${baseApiUrl}/${url}`, {
    method: method || 'GET',
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'none'
    }),
  });
  return fetch(request)
    .then(response => response.blob())
    .then(blob => {
      // Создаем новый Blob объект с полученными данными
      const pdfBlob = new Blob([blob], { type: 'application/pdf' });
      const year = new Date()
      const month = (year.getMonth()+1) < 10 ? `0${(year.getMonth()+1)}` : (year.getMonth()+1);
      const date = year.getDate() < 10 ? `0${year.getDate()}` : year.getDate();
      const fileName = article + '_' + year.getFullYear() + '-' + month + '-' + date + '.pdf'
      // Используем функцию saveAs() для сохранения файла на компьютере
      saveAs(pdfBlob, fileName);
    })
    .catch(error => {
      // Обработка ошибок
      console.error('Ошибка при скачивании файла:', error);
    });
};

export async function getPurposes() {
  const purps = await Api.dictionary('systemPurpose');
  return purps
}

export async function getData(){

  const [pt,ihrs,epc, bmt,imt,mec,pct,purps,pnm ] = await Promise.all([
  Api.dictionary('pumpType'),
  Api.dictionary('insulationHeatResistanceClass'),
  Api.dictionary('enclosureProtectionClass'),
  Api.dictionary('bodyMaterialType'),
  Api.dictionary('impellerMaterialType'),
  Api.dictionary('motorEfficiencyClass'),
  Api.dictionary('pipeConnectionType'),
  Api.dictionary('systemPurpose'),
  Api.dictionary('powerNetworkMode')
])
  return {
    pumpType:pt,
    insulationHeatResistanceClass:ihrs,
    enclosureProtectionClass:epc,
    bodyMaterialType:bmt,
    impellerMaterialType:imt,
    motorEfficiencyClass:mec,
    pipeConnectionType:pct,
    systemPurpose:purps,
    powerNetworkMode:pnm
  }

}

// function Auth(url, body, method) {
//   return ()=>authProvider.isAuhorized() ? getApi(url, body, method) : [];
// }

// function getFileLink(UUID) {
//   return `${baseApiUrl}/toc/GetFile?uuid=${UUID}&token=${getToken()}`;
// }

// function getImageLink(UUID) {
//   return `${baseApiUrl}/toc/GetImage?uuid=${UUID}&token=${getToken()}`;
// }

// function getXlsLink() {
//   return `${baseApiUrl}/xls/List?token=${getToken()}`;
// }

export const Api = {

  dictionary: (dict) => getApi(`api/dictionary/${encodeURIComponent(dict)}`),
  dictionaryById: (dict, id) =>getApi(`api/dictionary/${encodeURIComponent(dict)}/${encodeURIComponent(id)}`),

  devices: () => getApi(`api/devices`),
  devicesById: (id) => getApi(`api/devices/${encodeURIComponent(id)}`),
  select: (params) =>getApi(`api/devices/select?${params}`,undefined ,"POST"),
  addDevice: (body) => getApi(`api/devices`, body, "POST"),
  updateDevice: (body) => getApi(`api/devices`, body, "PUT"),
  deleteDevice: (id) => getApi(`api/devices/${encodeURIComponent(id)}`,undefined ,"DELETE"),

  charts: (id, pressure, flow, staticPressure) => 
          getApi(`api/devices/${encodeURIComponent(id)}/charts?pressure=${encodeURIComponent(pressure)}&flow=${encodeURIComponent(flow)}&staticPressure=${encodeURIComponent(staticPressure)}`,undefined ,"POST"),
  
  download: (id, article, calculatedEfficiency, targetFlow, targetPressure, staticPressure, purposes, body, calculatedFlow, calculatedPressure) => 
          getFile(`api/devices/${encodeURIComponent(id)}/results/download/?calculatedEfficiency=${encodeURIComponent(calculatedEfficiency)}&targetFlow=${encodeURIComponent(targetFlow)}&targetPressure=${encodeURIComponent(targetPressure)}&staticPressure=${encodeURIComponent(staticPressure)}&purposes=${encodeURIComponent(purposes)}&calculatedFlow=${encodeURIComponent(calculatedFlow)}&calculatedPressure=${encodeURIComponent(calculatedPressure)}`, article, body ,"POST"),
  regions: () => getApi(`api/dictionary/regions`),
  regionById: (id) => getApi(`api/dictionary/regions/${encodeURIComponent(id)}`),
  regionManagers: (id) => getApi(`api/dictionary/regions/${encodeURIComponent(id)}/managers`),
  managers: () => getApi(`api/dictionary/managers`),
  managerById: (id) => getApi(`api/dictionary/managers/${encodeURIComponent(id)}`),
  regionsByManager: (id) => getApi(`api/dictionary/managers/${encodeURIComponent(id)}/regions`),

  addDictionary: (dict, body) => getApi(`api/dictionary/${dict}`, body, "POST"),
  editDictionary: (dict, body) => getApi(`api/dictionary/${dict}`, body, "PUT"),
  deleteDictionary: (dict, body) => getApi(`api/dictionary/${dict}`, body, "DELETE"),

  //select: (body) => getApi(`api/devices/select`, body, "POST"),
//   getFileLink,
//   getImageLink,
//   getXlsLink
};
