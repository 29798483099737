import React, {useRef, useState, useEffect, forwardRef, useImperativeHandle} from 'react';
// import '../node_modules/react-vis/dist/style.css';
// import {XYPlot, XAxis, YAxis,VerticalGridLines, HorizontalGridLines, LineSeries, MarkSeries} from 'react-vis';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ScatterController,
    BubbleController
  } from 'chart.js';
  import { Line, getElementsAtEvent,Bubble, Chart } from 'react-chartjs-2';
import { isVisible } from '@testing-library/user-event/dist/utils';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ScatterController,
     BubbleController,
    Title,
    Tooltip,
    Legend
  )

  const colors = (col) => {
    switch (col){
      case 'Напор':
        return 'rgb(75, 192, 192)';
      case 'Мощность':
        return 'rgb(178, 34, 34)';
      case 'NPSH':
        return 'rgb(255, 69, 8)';
      case 'Характеристика системы':
        return 'rgb(0, 128, 0)';
    }
  }

  const metrics = (name) => {
    switch (name){
      case 'Напор':
        return 'м';
      case 'Мощность':
        return 'кВт';
      case 'NPSH':
        return 'м';
    }
  }


  const createDataSet = (data, labels, rad = -1, col = null, type = 'line') =>{
    return {
      type: type,
      label: labels,
      data: data,
      fill: false,
      borderColor: col? col : colors(labels),
      pointRadius: rad,
      order: 3
      //tension: 0.01,
    }
        }


const LineChart = forwardRef(({inData, chartLabels, saveChart, target = null, intersection = null }, ref) => {

    const chartRef = useRef(null);

    useImperativeHandle(ref, () => ({
      getAlert(){
      saveChart(chartRef.current.toBase64Image().replace('data:image/png;base64,', ''));
      }
    }))

    const [points, setPoints] = useState([]);
    const [labels, setLabels] = useState([]);
    const [data, setSetData] = useState({labels:[],datasets:[]});

    function range(from, to, step = 1) {
      return [...Array(Math.floor((to - from) / step) + 1)].map(
        (_, i) => from + i * step
      );
    }

useEffect(()=>{
  if(inData !== undefined && inData.length !== 0 && inData[0] !== undefined){
  const max = inData[0][inData[0].length-1].x
  setPoints(inData)
  const labels = range(0,max,max/inData[0].length)
  const datasets = []
  for (let i = 0; i < inData.length; i++) {
    if(chartLabels[i] === 'Характеристика системы'){
      const dataset = inData[i].slice()
      dataset[dataset.length-1] = {x:intersection[0],  y: intersection[1]}
      datasets.push(createDataSet(dataset,chartLabels[i]))
    }else{
      datasets.push(createDataSet(inData[i],chartLabels[i]))
    }
    
  }
  if(chartLabels.includes('Напор')){
    datasets.push(
      {
        type: 'bubble',
        label: 'point1',
        data:[{
          x:  target[0],
          y:  target[1],
          r: 6,
         
      }],
        backgroundColor: 'rgb(0, 128, 0)',
        order:0
      },
      {
        type: 'bubble',
        label: 'point2',
        data:[{
          x:  intersection[0],
          y:  intersection[1],
          r: 6,
          
          
      }],
        backgroundColor: 'rgb(75, 192, 192)',
        order:0
      }
    )
  }
  const data = {
    labels: labels,
     datasets: datasets,
  };
  setSetData(data)
}

},[inData])

  // const handleMouseMove = (event, chartElements) => {
  //   if (chartElements.length > 0) {
  //     const { _datasetIndex, _index } = chartElements[0];
  //     console.log({_datasetIndex, _index})
  //    // setPosition({ datasetIndex: _datasetIndex, index: _index });
  //   } else {
  //     const chartArea = event.chart.chartArea;
  //     const native = event.native;
  //     const offsetX = native.clientX - chartArea.left;
  //     const offsetY = native.clientY - chartArea.top;

  //     if (offsetX >= 0 && offsetX <= chartArea.right - chartArea.left &&
  //         offsetY >= 0 && offsetY <= chartArea.bottom - chartArea.top) {
  //           console.log({ x: null, y: null });
  //     } else {
  //       console.log({ x: offsetX, y: offsetY });
  //     }
  //   }
  // };

  const onClick = (event) => {
    let size = event.target
    const { offsetX, offsetY } = event.nativeEvent;
     const xValue = ((offsetX)/(size.width/labels[labels.length-1])).toFixed(3);
     const yValue = ((size.height - offsetY)/(size.height/100)).toFixed(3);

     let newPoints = [...points, {x:xValue,y:yValue}].sort((a,b)=>b.x - a.x)
     let newLabels = [...labels, xValue].sort()

    //setClickedPoints(newPoints);
    //setClickedLabels(newLabels)
  }

  var customTooltip = "Custom tooltip string";

  const options = {
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          text: 'м³/ч', // Единицы измерения для оси X
        },
      },
      y: {
        //type: 'linear',
        position: 'left',
        beginAtZero: true,
        title: {
          display: true,
          text: metrics(chartLabels[0]), // Единицы измерения для оси Y
        },
        // ticks: {
         
        //   callback: function(value) {
        //     return `${value}м`;
        //   }
        // }
         display: true,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,

        intersect: true,
        displayColors: false,
        yAlign:'top',
        callbacks: {
          label: function (context) {
            let label = ''; //context.label || "";
            const data = context.dataset.data;
            if (context.datasetIndex === 2) {
                label += `Заданный расход: ${context.parsed.x} м³/ч; Заданный напор: ${context.parsed.y} м`;
            }
            if (context.datasetIndex === 3) {
              label += ` Расчетный расход: ${(context.parsed.x).toFixed(2)} м³/ч; Расчетный напор: ${(context.parsed.y).toFixed(2)} м`;
          }
            return label;
          }
      }
      },
      legend: {
        display: true,
        labels: {
          filter: function (legendItem, chartData) {
            return legendItem.datasetIndex < 2; // Hide legend
          },
        },
      },
    },
    maintainAspectRatio: false
    //onHover: handleMouseMove,
  };


  return (
    <div >
      <Line width={450} height={250} ref={chartRef} data={data} options={options} />
    </div>
  );
});

export default LineChart;