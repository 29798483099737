import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import logoPng from './logo.png';
import './App.css';
import {Navbar} from 'react-bootstrap';
import {Nav, Tab, Tabs} from 'react-bootstrap';
import {Container, Row, Col,  Table, Button, Pagination} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Api, getData} from './services/apiProvider';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import objectToString from 'object-to-string';
import {FaSignOutAlt} from 'react-icons/fa';
import {Hourglass} from 'react-loader-spinner';
import {devicesToView, deviceToSpecification, getNames, devicesToAdminView} from './services/deviceConverter';
import EditDeviceModal from './EditDeviceModal';
import { getPump } from './models/Device';
import { useInterval } from './services/useInterval';
import DictTab from './dictTab';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60;

function AdminApp() {
    
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true);
  // const [purposes, setPurposes] = useState([])
  const [devices, setDevices] = useState([])
  const [tableData, setTableData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [deviceToEdit, setEditDevice] = useState(null)
  const [timeLeft, setTimeLeft] = useInterval(ONE_MINUTE, ONE_SECOND);
  const [validationErrors, setValidationErrors] = useState({});


  const spinnerStyle = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  useEffect(()=>{

    function fetchData(){
//     let opts = []
//     purposes.forEach(purp => {opts.push({ value: purp.id, label: purp.name })})
//     setOptions(opts)

//     const searchParams = new URLSearchParams(window.location.search);
//     const flow = Number(searchParams.get('flow') ?? '0');
//     const pressure = Number(searchParams.get('pressure') ?? '0');
//     const purpose = searchParams.get('purposes') ?? [];
//     const staticPressure = Number(searchParams.get('staticPressure') ?? '0');
//     setParams({"flow":flow,"pressure":pressure,"purposes": purpose, "staticPressure":staticPressure});
//     if (purpose !== null && purpose.length !==0 && opts.length !==0){
//       let newOpts = []
//       let asd = purpose.split(',')
//       asd.forEach(element => {
//         newOpts.push(opts.find(el => el.value === Number(element)))
//       });
//       setDefaultOptions(newOpts)
//     }
   }
  if (data && data.systemPurpose.length !==0){
    fetchData()
    setLoading(false)
  }
  },[data])

  const getDevices = async () => {
    const ds = await Api.devices()
    setDevices(ds)
  }

  const loadData = async () => {
    const d = await getData()
    setData(d)
  }

  const tryReload = () => {
    getDevices()
    loadData()
    setTimeLeft(ONE_MINUTE)
  }

//   useEffect(()=>{
// if(timeLeft === 0 && devices.length === 0){
//   loadData();
//   getDevices();
//   setTimeLeft(ONE_MINUTE)
//   console.log("timer")
// }
//   },[timeLeft])
   

useEffect(() =>{
  async function fetch(){

    // const prps = await getPurposes()
    // setPurposes(prps)

    loadData()
    getDevices()

  }
   fetch()
},[])

useEffect(() =>{
    if (devices.length !== 0){
        const data = devicesToAdminView(devices) 
        setTableData(data)
    }
    
  },[devices])

  const openCloseEdit = (d = null, isN = false) =>{
    setIsNew(isN)
    const device = !isN ? d : getPump()
    setEditDevice(device)
    setIsOpen(!isOpen)
    setValidationErrors({})
  }


  const  deletePump = async (id) =>{
    await Api.deleteDevice(id)
    getDevices()
  }

  // const addPump = (pump) =>{
  //   Api.addDevice(pump)
  // }

  // const updatePump = (pump) =>{
  //   Api.updateDevice(pump)
  // }

  const applyEdit = async (pump) =>{

    const errors = {};

    if (pump.modelName.length === 0) {
      errors.modelName = 'Обязательное поле';
    }
    if (pump.article.length === 0) {
      errors.article = 'Обязательное поле';
    }
    if (pump.pumpType === null) {
      errors.pumpType = 'Обязательное поле';
    }
    if (pump.nominalPower === 0.0) {
      errors.nominalPower = 'Обязательное поле';
    }
    if (pump.nominalCurrent === 0.0) {
      errors.nominalCurrent = 'Обязательное поле';
    }
    if (pump.availableVoltageDrop === 0) {
      errors.availableVoltageDrop = 'Обязательное поле';
    }
    if (pump.flowMax === 0.0) {
      errors.flowMax = 'Обязательное поле';
    }
    if (pump.weight=== 0.0) {
      errors.weight = 'Обязательное поле';
    }
    if (pump.bodyMaterialType === null) {
      errors.bodyMaterialType = 'Обязательное поле';
    }
    if (pump.impellerMaterialType === null) {
      errors.impellerMaterialType = 'Обязательное поле';
    }
    if (pump.pipeConnectionType === null) {
      errors.pipeConnectionType = 'Обязательное поле';
    }
    if (pump.inputDiameter === 0) {
      errors.inputDiameter = 'Обязательное поле';
    }
    if (pump.outputDiameter === 0) {
      errors.outputDiameter = 'Обязательное поле';
    }
    if (pump.insulationHeatResistanceClass === null) {
      errors.insulationHeatResistanceClass = 'Обязательное поле';
    }
    if (pump.enclosureProtectionClass === null) {
      errors.enclosureProtectionClass = 'Обязательное поле';
    }
    if (pump.rpmLimit === 0) {
      errors.rpmLimit = 'Обязательное поле';
    }
    if (pump.motorEfficiencyClass === null) {
      errors.motorEfficiencyClass = 'Обязательное поле';
    }
    if (pump.pumpedLiquidTemperature === 0) {
      errors.pumpedLiquidTemperature = 'Обязательное поле';
    }
    if (pump.workingPressureMax === 0.0) {
      errors.workingPressureMax = 'Обязательное поле';
    }
    if (pump.noiseLevelMax === 0.0) {
      errors.noiseLevelMax = 'Обязательное поле';
    }
    if (pump.systemPurposes.length === 0) {
      errors.systemPurposes = 'Обязательное поле';
    }
    if (pump.powerNetworkModes.length === 0) {
      errors.powerNetworkModes = 'Обязательное поле';
    }
    if (pump.valueA1 === undefined || pump.valueA1 === 0) {
      errors.valueA1 = 'Обязательное поле';
    }
    if (pump.valueB1 === undefined || pump.valueB1 === 0) {
      errors.valueB1 = 'Обязательное поле';
    }
    if (pump.valueC1 === undefined || pump.valueC1 === 0) {
      errors.valueC1 = 'Обязательное поле';
    }
    if (pump.valueD1 === undefined || pump.valueD1 === 0) {
      errors.valueD1 = 'Обязательное поле';
    }

    if (pump.valueA2 === undefined || pump.valueA2 === 0) {
      errors.valueA2 = 'Обязательное поле';
    }
    if (pump.valueB2 === undefined || pump.valueB2 === 0) {
      errors.valueB2 = 'Обязательное поле';
    }
    if (pump.valueC2 === undefined || pump.valueC2 === 0) {
      errors.valueC2 = 'Обязательное поле';
    }
    if (pump.valueD2 === undefined || pump.valueD2 === 0) {
      errors.valueD2 = 'Обязательное поле';
    }

    if (pump.valueA3 === undefined || pump.valueA3 === 0) {
      errors.valueA3 = 'Обязательное поле';
    }
    if (pump.valueB3 === undefined || pump.valueB3 === 0) {
      errors.valueB3 = 'Обязательное поле';
    }
    if (pump.valueC3 === undefined || pump.valueC3 === 0) {
      errors.valueC3 = 'Обязательное поле';
    }
    if (pump.valueD3 === undefined || pump.valueD3 === 0) {
      errors.valueD3 = 'Обязательное поле';
    }

    if (pump.valueA4 === undefined || pump.valueA4 === 0) {
      errors.valueA4 = 'Обязательное поле';
    }
    if (pump.valueB4 === undefined || pump.valueB4 === 0) {
      errors.valueB4 = 'Обязательное поле';
    }
    if (pump.valueC4 === undefined || pump.valueC4 === 0) {
      errors.valueC4 = 'Обязательное поле';
    }
    if (pump.valueD4 === undefined || pump.valueD4 === 0) {
      errors.valueD4 = 'Обязательное поле';
    }

    if (pump.imageBase64 === undefined || pump.imageBase64.length === 0) {
      errors.imageBase64 = 'Загрузите изображение';
    }
    // Если есть ошибки валидации, обновляем состояние
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
  
    // Ваш код для обработки отправки формы
    setValidationErrors({})
    if(isNew){
      await Api.addDevice(pump)
    }else{
      await Api.updateDevice(pump)
    }
    setIsOpen(!isOpen)
    setEditDevice(getPump())
    getDevices()
  }

const signOut = () =>{
  
}
const headerSortingStyle = { backgroundColor: '#D3D3D3' };

const columns = [{
  dataField: 'id',
  text: 'id'
}, {
  dataField: 'modelName',
  text: 'Mодель',
  sort: true,
  headerSortingStyle
},{
  dataField: 'systemPurposes',
  text: 'Назнаначение системы',
  sort: true,
  headerSortingStyle
},{
  dataField: 'article',
  text: 'Артикул',
  sort: true,
  headerSortingStyle
},
{
  dataField: 'nominalPower',
  text: 'Номинальная мощность, кВт',
  sort: true,
  headerSortingStyle
},
{
  dataField: 'flowMin',
  text: 'Расход минимальный, м3/ч',
  sort: true,
  headerSortingStyle
},
{
  dataField: 'flowMax',
  text: 'Расход максимальный, м3/ч',
  sort: true,
  headerSortingStyle
},
{
  dataField: 'df1',
  isDummyField: true,
  text: 'actions',
  formatter: (cellContent, row) => {
    return (
      <>
      <Button variant="primary" size="sm" onClick={()=>openCloseEdit(devices.find(el=> el.id === row.id))}>
        Редактировать
      </Button>{' '}
      <Button variant="warning" size="sm" onClick={()=>deletePump(row.id)}>
        Удалить
      </Button>
    </>
    );
  }
}
];

const defaultSorted = [{
  dataField: 'modelName',
  order: 'asc'
}];

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
     { from } - { to } из { size }
  </span>
);

const options = {
  paginationSize: 5,
  pageStartIndex: 1,
  hidePageListOnlyOnePage: true,
  firstPageText: '|<',
  prePageText: '<',
  nextPageText: '>',
  lastPageText: '>|',
  nextPageTitle: 'Следующая страница',
  prePageTitle: 'Предыдущая страница',
  firstPageTitle: 'Первая страница',
  lastPageTitle: 'Последняя страница',
  showTotal: true,
  paginationTotalRenderer: customTotal,
  disablePageTitle: true,
  sizePerPageList: [{
    text: '50', value: 50
  }, {
    text: '100', value: 100
  }]
};



  return (
    <div className="AdminApp">
      <EditDeviceModal device={deviceToEdit} inputData={data} isOpen={isOpen} validErrors={validationErrors} onClose={openCloseEdit} appyMethod={applyEdit}/>
      <Navbar className='mb-2' bg="light" data-bs-theme="light">
        <Container>
        <Navbar.Brand href="https://pulsarm.ru/">
        <img
              src={logoPng}
              width="130"
              height="50"
              className="d-inline-block align-top"
              alt="Pulsar"
            />
        </Navbar.Brand>
          {/* <Nav className="me-auto">
            <Nav.Link href="https://pulsarm.ru/">Pulsar</Nav.Link>
          </Nav> */}
          <Nav.Item id='exitButton' onClick={signOut}><FaSignOutAlt/>
          </Nav.Item>
        </Container>
      </Navbar>
      
        { loading ?
        timeLeft > 0 ?
        <div style={spinnerStyle}>
         <Hourglass
          visible={true}
          height="140"
          width="140"
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={['#306cce', '#72a1ed']}
        />
        </div>
        : <div style={spinnerStyle}> <p>Не удалось подключиться к серверу</p>
          <Button onClick={()=>tryReload()}>Повторить попытку</Button>
        </div>
         :
  <Container className='Main-Container'>
      <Tabs
      defaultActiveKey="pumps"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="pumps" title="Насосы">
      <Container>
        <Row className="mb-3">
            <Col xs={2} md={2}><Button onClick={()=>openCloseEdit(null,true)}>Добавить</Button></Col>

        </Row>
        <Row>
          {devices.length !== 0 &&
        <BootstrapTable keyField='id' data={ tableData } columns={ columns } pagination={ paginationFactory(options)} defaultSorted={defaultSorted} />
          }
    </Row>
</Container>
      </Tab>
      <Tab eventKey="dicts" title="Словари">
        <DictTab />
      {/* <Container>
        <Row className="mb-3">
            <Col xs={2} md={2}><Button onClick={()=>openCloseEdit(null,true)}>Добавить</Button></Col>
        </Row>
        <Row>
          {devices.length !== 0 &&
        <BootstrapTable keyField='id' data={ tableData } columns={ columns } pagination={ paginationFactory(options)} defaultSorted={defaultSorted} />
          }
    </Row>
</Container> */}
      </Tab>
      {/* <Tab eventKey="profile" title="Profile">
        Tab content for Profile
      </Tab> */}
    </Tabs>
      </Container>
  }
    </div>
  );
}

export default AdminApp;
