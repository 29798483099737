import React, { useState, useEffect } from 'react';

const QuantitySelector = ({setCount}) => {
  const [quantity, setQuantity] = useState(1);

useEffect(()=>{
    setCount(quantity)
},[quantity])

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div>
      <button onClick={handleDecrement}>-</button>
      <span>{quantity}</span>
      <button onClick={handleIncrement}>+</button>
    </div>
  );
};

export default QuantitySelector;