import React from 'react';
import {Container, Row,Col, Stack} from 'react-bootstrap'
import { version } from './services/constants';


const Footer = () => {

  const year = new Date().getFullYear();


  return (
    <footer className="bg-light text-dark ">
      <Container>
       
        <Stack direction="horizontal" gap={2}>
            <p className="p-2">1997-{year} &copy; Тепловодохран</p>
          <p className="p-2 ms-auto">v {version}</p>
        </Stack>
      </Container>
    </footer>
  );
};

export default Footer;