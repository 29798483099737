import {reducer as ccReducer} from "concent";

export async function setToken(p, m, ac) {
  localStorage.setItem('token', p);
  ac.setState({ token: p, logined: !!p });
  await ccReducer.request.reloadAsync();
  await ccReducer.requestByPage.reloadAsync();
  // await ccReducer.objects.reloadAsync();
  await ccReducer.listPageObjects.reloadAsync();
  return undefined;
}

export async function logout() {
  localStorage.removeItem('token');
  return { token: '', logined: false }
}

export async function initToken(p, m, ac) {
  const token = localStorage.getItem('token');
  if (token)
    ac.dispatch(setToken,token);
  else
    ac.dispatch(logout);
}
