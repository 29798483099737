import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import React from "react";
import App from './App';
import AdminApp from './AdminApp';
import Login from './login';
import {useConcent} from "concent";

export function AppRouter() {
    const { state } = useConcent("auth");
    const logined = true //state.logined;
    const isFirstLoad = window.location.search.length === 0 //|| window.location.search === '?flow=0&pressure=0&purposes=&staticPressure=0'

    const admin = () => {return(logined ? <AdminApp /> : <Login/>)}
    const pathname = window.location.pathname

  return <Router>
     {/* <TechAlert message={"Ведуться технические работы! Поиск и сортировка временно недоступны. Для поиска заявок пользуйтесь переключением страниц."} /> */}
    <Switch>
    {
          pathname!== "/" && (/\/$/).test(pathname) &&
          <Redirect to={pathname.slice(0, -1)} />
     }
      <Route path="/" exact component={props =><App isFirst={isFirstLoad} />}/>
      <Route path="/admin/" exact component={admin}/>
      {/* <Route path="/new" component={NewList}/>
      <Route path="/request/:id" component={dataFormFamily('request')}/> */}
    </Switch>
  </Router>;
}