import React, { useState, useEffect } from 'react';
import {Container, Form }from 'react-bootstrap';
import QuantitySelector from './QuantitySelector';
import { Modal, Row, Col, Tab, Tabs, Alert } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import MultiSelectDropdown from './MultiSelectDropdown';
import ImageUploader from 'react-images-upload';


const animatedComponents = makeAnimated();
const EditDeviceModal = ({ device, inputData, isOpen, validErrors, onClose, appyMethod }) => {

  const [deviceData, setDeviceData] = useState(null)
  const [data, setData] = useState({})

  const [pumpType, setPumpType] = useState([])
  const [bodyMaterialType, setBodyMaterialType] = useState([])
  const [impellerMaterialType, setImpellerMaterialType] = useState([])
  const [pipeConnectionType, setPipeConnectionType] = useState([])
  const [insulationHeatResistanceClass, setInsulationHeatResistanceClass] = useState([])
  const [enclosureProtectionClass, setEnclosureProtectionClass] = useState([])
  const [motorEfficiencyClass, setMotorEfficiencyClass] = useState([])
  const [systemPurposes, setSystemPurposes] = useState([])
  const [powerNetworkModes, setPowerNetworkModes] = useState([])

  const [selectedPurposes,setSelectedPurposes] = useState([])
  const [selectedPowers,setelectedPowers] = useState([])

  const [coefficients, setCoefficients] = useState([])

  useEffect(()=>{
    //if(device !== null){
        setDeviceData(device)
    //}
  },[device])

  // useEffect(()=>{
  //   if(inputData !== null){
       
  //   }
  // },[inputData])

  const toOpt = (dict) => {
    let opts = []
    if (dict !== undefined && dict !== null)
        dict.forEach(el => {opts.push({ value: el.id, label: el.name })})
    return opts
  }

  useEffect(()=>{
    if(inputData !== undefined && inputData !== null){
        setData(inputData)
        setPumpType(toOpt(inputData.pumpType))
        setBodyMaterialType(toOpt(inputData.bodyMaterialType))
        setImpellerMaterialType(toOpt(inputData.impellerMaterialType))
        setPipeConnectionType(toOpt(inputData.pipeConnectionType))
        setInsulationHeatResistanceClass(toOpt(inputData.insulationHeatResistanceClass))
        setEnclosureProtectionClass(toOpt(inputData.enclosureProtectionClass))
        setMotorEfficiencyClass(toOpt(inputData.motorEfficiencyClass))
        setSystemPurposes(toOpt(inputData.systemPurpose))
        setPowerNetworkModes(toOpt(inputData.powerNetworkMode))
    }
  },[inputData])

useEffect(()=>{
if(isOpen){
    if(device !== null){
        let purps = []
        let pows = []
        device.systemPurposes.forEach(element => {
            purps.push(systemPurposes.find(el => el.value === (element.id)))
          });
        device.powerNetworkModes.forEach(element => {
            pows.push(powerNetworkModes.find(el => el.value === (element.id)))
          });
        setSelectedPurposes(purps)
        setelectedPowers(pows)
    }else{
        setSelectedPurposes([])
        setelectedPowers([])
    }
    }else{
      setSelectedPurposes([])
        setelectedPowers([])
    }
},[isOpen])

  const changeParams = (e) => {
    let par = e.target.id
    let value = e.target.value

    setDeviceData({...deviceData, [par]:value});
  }

  const changeSelect = (val, name) => {
    const dict = data[name].find(el=> el.id === val.value)
    setDeviceData({...deviceData, [name]: dict});
  }

  const changeMultiSelect = (options, name) => {
    let values = []
    
    if (options !== undefined && options.length > 0){
        options.forEach((opt) => values.push(opt.value))
        switch(name){
            case "purps":
                const purps = [];
                values.forEach(vl=> purps.push( data.systemPurpose.find(el=> el.id === vl)));
                setDeviceData({...deviceData, systemPurposes:purps});
                return;

            case "power":
                const powers = [];
                values.forEach(vl=> powers.push( data.powerNetworkMode.find(el=> el.id === vl)));
                setDeviceData({...deviceData, powerNetworkModes:powers});
                return;
        }
    }
  }

  const convertImageToBase64 = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (e) => {
        resolve(e.target.result);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(imageFile);
    });
  }

  const onChangeImage = (imageList) => {
    if (imageList.length > 0){
      convertImageToBase64(imageList[0])
  .then((base64String) => {
    let index = base64String.indexOf(',')
    const newImg = base64String.slice(index+1)
    setDeviceData({...deviceData, imageBase64:newImg});
  })
  .catch((error) => {
    console.error('Произошла ошибка при преобразовании:', error);
  });
    }
  };


  if (!isOpen) return null;

  return (
    <>{deviceData &&
    <Modal fullscreen={true} show={isOpen} onHide={onClose} centered={false} className="EditDeviceModal">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
      <Tabs
      defaultActiveKey="pump"
      id="uncontrolled-tab-1"
      className="mb-3"
    >
      <Tab eventKey="pump" title="Насос">
      <Container>
      <Form>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="modelName">
          <Form.Label>Модель</Form.Label>
          <Form.Control type="text" placeholder="Название модели" name='modelName' value={deviceData.modelName} onChange={changeParams}/>
          {validErrors.modelName && <Alert variant="danger">{validErrors.modelName}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="article">
          <Form.Label>Артикул</Form.Label>
          <Form.Control type="text" placeholder="Артикул" name='article' value={deviceData.article} onChange={changeParams}/>
          {validErrors.article && <Alert variant="danger">{validErrors.article}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="pumpType">
        <Form.Label >Тип насоса</Form.Label>
        <Select
      value={pumpType.find(el => deviceData && deviceData.pumpType ? el.value === deviceData.pumpType.id : null)}
      closeMenuOnSelect={true}
      components={animatedComponents}
      onChange={(newValue)=>changeSelect(newValue,'pumpType')}
      options={pumpType}
    />
    {validErrors.pumpType && <Alert variant="danger">{validErrors.pumpType}</Alert>}
      </Form.Group>

      <Form.Group as={Col} controlId="nominalPower">
          <Form.Label>Номинальная мощность, кВт</Form.Label>
          <Form.Control type="number" placeholder="Номинальная мощность, кВт" name='nominalPower' value={deviceData.nominalPower} onChange={changeParams}/>
          {validErrors.nominalPower && <Alert variant="danger">{validErrors.nominalPower}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="nominalCurrent">
          <Form.Label>Номинальный ток, А</Form.Label>
          <Form.Control type="number" placeholder="Номинальный ток, А" name='nominalCurrent' value={deviceData.nominalCurrent} onChange={changeParams}/>
          {validErrors.nominalCurrent && <Alert variant="danger">{validErrors.nominalCurrent}</Alert>}
        </Form.Group>

      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="availableVoltageDrop">
          <Form.Label>Допустимое падение напряжения, %</Form.Label>
          <Form.Control type="number" placeholder="Допустимое падение напряжения, %" name='availableVoltageDrop' value={deviceData.availableVoltageDrop} onChange={changeParams}/>
          {validErrors.availableVoltageDrop && <Alert variant="danger">{validErrors.availableVoltageDrop}</Alert>}
        </Form.Group>


        <Form.Group as={Col} controlId="flowMin">
          <Form.Label>Расход минимальный, м3/ч</Form.Label>
          <Form.Control type="number" placeholder="Расход минимальный, м3/ч" name='flowMin' value={deviceData.flowMin} onChange={changeParams}/>
        </Form.Group>

        <Form.Group as={Col} controlId="flowMax">
          <Form.Label>Расход максимальный, м3/ч</Form.Label>
          <Form.Control type="number" placeholder="Расход максимальный, м3/ч" name='flowMax' value={deviceData.flowMax} onChange={changeParams}/>
          {validErrors.flowMax && <Alert variant="danger">{validErrors.flowMax}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="weight">
          <Form.Label>Вес, кг</Form.Label>
          <Form.Control type="number" placeholder="Вес, кг" name='weight' value={deviceData.weight} onChange={changeParams}/>
          {validErrors.weight && <Alert variant="danger">{validErrors.weight}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="bodyMaterialType">
        <Form.Label >Материал корпуса насоса</Form.Label>
        <Select
      value={bodyMaterialType.find(el =>  deviceData && deviceData.bodyMaterialType ? el.value === deviceData.bodyMaterialType.id : null)}
      closeMenuOnSelect={true}
      components={animatedComponents}
      onChange={(newValue)=>changeSelect(newValue,'bodyMaterialType')}
      options={bodyMaterialType}
    />
    {validErrors.bodyMaterialType && <Alert variant="danger">{validErrors.bodyMaterialType}</Alert>}
      </Form.Group>
      </Row>

      <Row>
      <Form.Group as={Col} controlId="impellerMaterialType">
        <Form.Label >Материал рабочего колеса</Form.Label>
        <Select
      value={impellerMaterialType.find(el =>  deviceData && deviceData.impellerMaterialType ? el.value === deviceData.impellerMaterialType.id : null)}
      closeMenuOnSelect={true}
      components={animatedComponents}
      onChange={(newValue)=>changeSelect(newValue,'impellerMaterialType')}
      options={impellerMaterialType}
    />
    {validErrors.impellerMaterialType && <Alert variant="danger">{validErrors.impellerMaterialType}</Alert>}
      </Form.Group>

      <Form.Group as={Col} controlId="pipeConnectionType">
        <Form.Label >Тип присоединения к трубопроводу</Form.Label>
        <Select
      value={pipeConnectionType.find(el =>  deviceData && deviceData.pipeConnectionType ? el.value === deviceData.pipeConnectionType.id : null)}
      closeMenuOnSelect={true}
      components={animatedComponents}
      onChange={(newValue)=>changeSelect(newValue,'pipeConnectionType')}
      options={pipeConnectionType}
    />
    {validErrors.pipeConnectionType && <Alert variant="danger">{validErrors.pipeConnectionType}</Alert>}
      </Form.Group>

      <Form.Group as={Col} controlId="inputDiameter">
          <Form.Label>Диаметр патрубка на всасывающей стороне, мм</Form.Label>
          <Form.Control type="number" placeholder="" name='inputDiameter' value={deviceData.inputDiameter} onChange={changeParams}/>
          {validErrors.inputDiameter && <Alert variant="danger">{validErrors.inputDiameter}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="outputDiameter">
          <Form.Label>Диаметр патрубка на напорной стороне, мм</Form.Label>
          <Form.Control type="number" placeholder="" name='outputDiameter' value={deviceData.outputDiameter} onChange={changeParams}/>
          {validErrors.outputDiameter && <Alert variant="danger">{validErrors.outputDiameter}</Alert>}
        </Form.Group>

      <Form.Group as={Col} controlId="insulationHeatResistanceClass">
        <Form.Label >Класс нагревостойкости изоляции</Form.Label>
        <Select
      value={insulationHeatResistanceClass.find(el =>  deviceData && deviceData.insulationHeatResistanceClass ? el.value === deviceData.insulationHeatResistanceClass.id : null)}
      closeMenuOnSelect={true}
      components={animatedComponents}
      onChange={(newValue)=>changeSelect(newValue,'insulationHeatResistanceClass')}
      options={insulationHeatResistanceClass}
    />
    {validErrors.insulationHeatResistanceClass && <Alert variant="danger">{validErrors.insulationHeatResistanceClass}</Alert>}
      </Form.Group>
      </Row>

      <Row>
      <Form.Group as={Col} controlId="enclosureProtectionClass">
        <Form.Label >Степень защиты</Form.Label>
        <Select
      value={enclosureProtectionClass.find(el =>  deviceData && deviceData.enclosureProtectionClass ? el.value === deviceData.enclosureProtectionClass.id : null)}
      closeMenuOnSelect={true}
      components={animatedComponents}
      onChange={(newValue)=>changeSelect(newValue,'enclosureProtectionClass')}
      options={enclosureProtectionClass}
    />
    {validErrors.enclosureProtectionClass && <Alert variant="danger">{validErrors.enclosureProtectionClass}</Alert>}
      </Form.Group>

      <Form.Group as={Col} controlId="rpmLimit">
          <Form.Label>Максимальная частота вращения об/мин</Form.Label>
          <Form.Control type="number" placeholder="" name='rpmLimit' value={deviceData.rpmLimit} onChange={changeParams}/>
          {validErrors.rpmLimit && <Alert variant="danger">{validErrors.rpmLimit}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="efficiency">
          <Form.Label>КПД</Form.Label>
          <Form.Control type="number" placeholder="" name='efficiency' value={deviceData.efficiency} onChange={changeParams}/>
        </Form.Group>

      <Form.Group as={Col} controlId="motorEfficiencyClass">
        <Form.Label >Класс эффективности мотора</Form.Label>
        <Select
      value={motorEfficiencyClass.find(el =>  deviceData && deviceData.motorEfficiencyClass ? el.value === deviceData.motorEfficiencyClass.id : null)}
      closeMenuOnSelect={true}
      components={animatedComponents}
      onChange={(newValue)=>changeSelect(newValue,'motorEfficiencyClass')}
      options={motorEfficiencyClass}
    />
    {validErrors.motorEfficiencyClass && <Alert variant="danger">{validErrors.motorEfficiencyClass}</Alert>}
      </Form.Group>

      <Form.Group as={Col} controlId="pumpedLiquidTemperature">
          <Form.Label>Максимальная температура перекачиваемой среды, °С</Form.Label>
          <Form.Control type="number" placeholder="" name='pumpedLiquidTemperature' value={deviceData.pumpedLiquidTemperature} onChange={changeParams}/>
          {validErrors.pumpedLiquidTemperature && <Alert variant="danger">{validErrors.pumpedLiquidTemperature}</Alert>}
        </Form.Group>

      </Row>

      <Row>
      <Form.Group as={Col} controlId="workingPressureMax">
          <Form.Label>Максимальное рабочее давление, бар</Form.Label>
          <Form.Control type="number" placeholder="" name='workingPressureMax' value={deviceData.workingPressureMax} onChange={changeParams}/>
          {validErrors.workingPressureMax && <Alert variant="danger">{validErrors.workingPressureMax}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="staticPressure">
          <Form.Label>Статическое давление, бар</Form.Label>
          <Form.Control type="number" placeholder="" name='staticPressure' value={deviceData.staticPressure} onChange={changeParams}/>
        </Form.Group>

        <Form.Group as={Col} controlId="noiseLevelMax">
          <Form.Label>Максимальный уровень шума, дБа</Form.Label>
          <Form.Control type="number" placeholder="" name='noiseLevelMax' value={deviceData.noiseLevelMax} onChange={changeParams}/>
          {validErrors.noiseLevelMax && <Alert variant="danger">{validErrors.noiseLevelMax}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="systemPurposes">
        <Form.Label >Назначение системы</Form.Label>
        {systemPurposes &&
      <MultiSelectDropdown handleSelect={changeMultiSelect} options={systemPurposes} defaultOpts={selectedPurposes} name="purps" />
        }
        {validErrors.systemPurposes && <Alert variant="danger">{validErrors.systemPurposes}</Alert>}
      </Form.Group>

      <Form.Group as={Col} controlId="powerNetworkModes">
        <Form.Label >Напряжение питания</Form.Label>
      {powerNetworkModes && <MultiSelectDropdown handleSelect={changeMultiSelect} options={powerNetworkModes} defaultOpts={selectedPowers} name="power" />}
      {validErrors.powerNetworkModes && <Alert variant="danger">{validErrors.powerNetworkModes}</Alert>}
      </Form.Group>

      </Row>

    </Form>
    </Container>
      </Tab>
      <Tab eventKey="coefs" title="Коэффициенты">
         <Container>
         <Form>
      <Row className="mb-3">
      <Form.Label>Напор</Form.Label>
      <Form.Group as={Col} controlId="valueA1">
          <Form.Label>A1</Form.Label>
          <Form.Control type="number" placeholder="" name='valueA1' value={deviceData.valueA1} onChange={changeParams}/>
          {validErrors.valueA1 && <Alert variant="danger">{validErrors.valueA1}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueB1">
          <Form.Label>B1</Form.Label>
          <Form.Control type="number" placeholder="" name='valueB1' value={deviceData.valueB1} onChange={changeParams}/>
          {validErrors.valueB1 && <Alert variant="danger">{validErrors.valueB1}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueC1">
          <Form.Label>C1</Form.Label>
          <Form.Control type="number" placeholder="" name='valueC1' value={deviceData.valueC1} onChange={changeParams}/>
          {validErrors.valueC1 && <Alert variant="danger">{validErrors.valueC1}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueD1">
          <Form.Label>D1</Form.Label>
          <Form.Control type="number" placeholder="" name='valueD1' value={deviceData.valueD1} onChange={changeParams}/>
          {validErrors.valueD1 && <Alert variant="danger">{validErrors.valueD1}</Alert>}
        </Form.Group>
      </Row>
      <Row className="mb-3">
      <Form.Label>Мощность</Form.Label>
      <Form.Group as={Col} controlId="valueA2">
          <Form.Label>A2</Form.Label>
          <Form.Control type="number" placeholder="" name='valueA2' value={deviceData.valueA2} onChange={changeParams}/>
          {validErrors.valueA2 && <Alert variant="danger">{validErrors.valueA2}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueB2">
          <Form.Label>B2</Form.Label>
          <Form.Control type="number" placeholder="" name='valueB2' value={deviceData.valueB2} onChange={changeParams}/>
          {validErrors.valueB2 && <Alert variant="danger">{validErrors.valueB2}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueC2">
          <Form.Label>C2</Form.Label>
          <Form.Control type="number" placeholder="" name='valueC2' value={deviceData.valueC2} onChange={changeParams}/>
          {validErrors.valueC2 && <Alert variant="danger">{validErrors.valueC2}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueD2">
          <Form.Label>D2</Form.Label>
          <Form.Control type="number" placeholder="" name='valueD2' value={deviceData.valueD2} onChange={changeParams}/>
          {validErrors.valueD2 && <Alert variant="danger">{validErrors.valueD2}</Alert>}
        </Form.Group>
      </Row>

      <Row>
      <Form.Label>КПД</Form.Label>
      <Form.Group as={Col} controlId="valueA3">
          <Form.Label>A3</Form.Label>
          <Form.Control type="number" placeholder="" name='valueA3' value={deviceData.valueA3} onChange={changeParams}/>
          {validErrors.valueA3 && <Alert variant="danger">{validErrors.valueA3}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueB3">
          <Form.Label>B3</Form.Label>
          <Form.Control type="number" placeholder="" name='valueB3' value={deviceData.valueB3} onChange={changeParams}/>
          {validErrors.valueB3 && <Alert variant="danger">{validErrors.valueB3}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueC3">
          <Form.Label>C3</Form.Label>
          <Form.Control type="number" placeholder="" name='valueC3' value={deviceData.valueC3} onChange={changeParams}/>
          {validErrors.valueC3 && <Alert variant="danger">{validErrors.valueC3}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueD3">
          <Form.Label>D3</Form.Label>
          <Form.Control type="number" placeholder="" name='valueD3' value={deviceData.valueD3} onChange={changeParams}/>
          {validErrors.valueD3 && <Alert variant="danger">{validErrors.valueD3}</Alert>}
        </Form.Group>
      </Row>

      <Row>
      <Form.Label>Npsh</Form.Label>
      <Form.Group as={Col} controlId="valueA4">
          <Form.Label>A4</Form.Label>
          <Form.Control type="number" placeholder="" name='valueA4' value={deviceData.valueA4} onChange={changeParams}/>
          {validErrors.valueA4 && <Alert variant="danger">{validErrors.valueA4}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueB4">
          <Form.Label>B4</Form.Label>
          <Form.Control type="number" placeholder="" name='valueB4' value={deviceData.valueB4} onChange={changeParams}/>
          {validErrors.valueB4 && <Alert variant="danger">{validErrors.valueB4}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueC4">
          <Form.Label>C4</Form.Label>
          <Form.Control type="number" placeholder="" name='valueC4' value={deviceData.valueC4} onChange={changeParams}/>
          {validErrors.valueC4 && <Alert variant="danger">{validErrors.powerNetwvalueC4orkModes}</Alert>}
        </Form.Group>

        <Form.Group as={Col} controlId="valueD4">
          <Form.Label>D4</Form.Label>
          <Form.Control type="number" placeholder="" name='valueD4' value={deviceData.valueD4} onChange={changeParams}/>
          {validErrors.valueD4 && <Alert variant="danger">{validErrors.valueD4}</Alert>}
        </Form.Group>
      </Row>

    </Form>
         </Container>
       </Tab>
       <Tab eventKey="image" title="Изображение">
         <Container>
          {/* <img  src={`data:image/png;base64,${deviceData.imageBase64}`}/> */}
          {/* <ImageUploading
        multiple={false}
        value={deviceData.imageBase64}
        singleImage={true}
        onChange={onChangeImage}
        maxNumber={1}
        dataURLKey="dataURL"
      >
          {({ imageList,onImageUpload, onImageUpdate, onImageRemove, dragProps, isDragging }) => (
    <div {...dragProps}>
      <Row> <Col md="auto">
      <img  width='320' height='240'  src={`data:image/png;base64,${deviceData.imageBase64}`}/>
      <button onClick={() => onImageUpdate(0)}>Update</button>
            <button onClick={() => onImageRemove(0)}>Remove</button>
      </Col>
      </Row>
      <Row>
      <Col md="auto">
      <button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button>
            </Col>
      </Row>
    </div>
  )}
  </ImageUploading> */}
   {deviceData.imageBase64 && (
        <div>
          <h2>Предпросмотр загруженного изображения</h2>
          <img src={`data:image/png;base64,${deviceData.imageBase64}`} alt="Uploaded" style={{ width: '100%', maxWidth: '300px' }} />
        </div>
      )}
   <ImageUploader
      withIcon={true}
      buttonText="Выберите изображение"
      onChange={onChangeImage}
      imgExtension={['.jpg','.png']}
      maxFileSize={2097152}
      singleImage={true}
      fileSizeError='Файл слишком большой'
      fileTypeError='Тип файла не поддерживается'
      label='Максимальный размер файла: 2Мб. Разрешенный формат .jpg,.png'
    />

         </Container>
         {validErrors.imageBase64 && <Alert variant="danger">{validErrors.imageBase64}</Alert>}
         </Tab>
    </Tabs>
      </Modal.Body>
      <Modal.Footer>
      <button className='btn btn-primary' onClick={()=>appyMethod(deviceData)}>Сохранить</button>
      <button className='btn btn-warning' onClick={onClose}>Закрыть</button>
      </Modal.Footer>
    </Modal>}
    </>
  );
};
export default EditDeviceModal;