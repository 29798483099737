import React, { useState, useEffect } from 'react';
import {Container, Form }from 'react-bootstrap';
import QuantitySelector from './QuantitySelector';
import { Modal, Row, Col, Tab, Tabs, Alert } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import MultiSelectDropdown from './MultiSelectDropdown';
import ImageUploader from 'react-images-upload';


const animatedComponents = makeAnimated();
const EditDictModal = ({ dict, isOpen, validErrors, onClose, appyMethod }) => {

  const [dictData, setDictData] = useState(dict)

  useEffect(()=>{
    setDictData(dict)
  },[dict])


  const changeParams = (e) => {
    let par = e.target.id
    let value = e.target.value

    setDictData({...dictData, [par]:value});
  }

  if (!isOpen) return null;

  return (
    <>{dictData &&
    <Modal show={isOpen} onHide={onClose} backdrop='static' centered={false} className="EditDictModal">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
      <Form>
      {/* <Row className="mb-3"> */}
        <Form.Group as={Row} controlId="name">
          <Form.Label>Имя</Form.Label>
          <Form.Control type="text" name='name' value={dictData.name} onChange={changeParams}/>
          {validErrors.name && <Alert variant="danger">{validErrors.name}</Alert>}
        </Form.Group>

        <Form.Group as={Row} controlId="description">
          <Form.Label>Описание</Form.Label>
          <Form.Control as="textarea" name='description' value={dictData.description} onChange={changeParams}/>
        </Form.Group>
    {/* </Row> */}
    </Form>
      </Modal.Body>
      <Modal.Footer>
      <button className='btn btn-primary' onClick={()=>appyMethod(dictData)}>Сохранить</button>
      <button className='btn btn-warning' onClick={onClose}>Закрыть</button>
      </Modal.Footer>
    </Modal>}
    </>
  );
};
export default EditDictModal;