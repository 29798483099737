import React, {useRef, useState, useMemo, useEffect} from 'react';
import {Button}from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const TableView = ({data, addToOrder, setSelection}) => {
    const tableRef = useRef();
    const [tableData, setTableData] = useState(data);

    const [rowSelection, setRowSelection] = useState();

    
    useEffect(()=>{
      setTableData(data)
      if(tableData.length !== 0)
        setRowSelection(tableData[0].id)
    },[data, tableData])
    

    useEffect(() => {
      if (rowSelection !== undefined && rowSelection !== null)
        setSelection(rowSelection)
    }, [rowSelection]);

    const openData = (pumpId)=> {
      //console.log(pumpId)
  }
  
  const clickH = (pumpId) => {
    console.log(pumpId)
    }

    const columns = [/*{
      dataField: 'id',
      text: 'Product ID'
    },*/ {
      dataField: 'code',
      text: 'Артикул'
    }, {
      dataField: 'name',
      text: 'Наименование'
    }
    // ,
    // {
    //   dataField: 'df1',
    //   isDummyField: true,
    //   text: 'Добавить',
    //   formatter: (cellContent, row) => {
    //     return (
    //       <Button variant='primary' onClick={()=>clickH(row.id)}>Добавить</Button>
    //     );
    //   }
    // }
  ];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
         { from } - { to } из { size }
      </span>
    );

    const handleOnSelect = (row, isSelect) => {
      setRowSelection(row.id)
      return true;
    }
    
    const options = {
      paginationSize: 3,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true,
      // withFirstAndLast: false,
      // hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      firstPageText: '|<',
      prePageText: '<',
      nextPageText: '>',
      lastPageText: '>|',
      nextPageTitle: 'Следующая страница',
      prePageTitle: 'Предыдущая страница',
      firstPageTitle: 'Первая страница',
      lastPageTitle: 'Последняя страница',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }]
    };

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true,
      onSelect: handleOnSelect,
      classes: 'table-secondary',
      selected: [rowSelection]
    };

  return (
    
    <div >
        <BootstrapTable bootstrap4 keyField='id' data={ tableData } columns={ columns } pagination={ paginationFactory(options)} selectRow={selectRow}  />
    </div>
  );
};

export default TableView;