import React, {useRef, useState, useEffect} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import {Table}from 'react-bootstrap';


const Specifications = ({data}) => {
  
  const [tableData, setTableData] = useState(data);
  const [containerHeight, setHeight] = useState(0);

  useEffect(()=>{
    setHeight(document.getElementById('chartRow') ? document.getElementById('chartRow').clientHeight * 0.9 : 0)
    setTableData(data)
  },[data])

  return (
    <Container style={{ height: containerHeight, overflowY: 'auto' }}>
      <Row>
      <Col md="auto">
      <img  width='320' height='240' src={`data:image/png;base64,${tableData['img']}`}/>
      </Col>
      </Row>
      <Row>
      <Table>
      <tbody>
      {Object.keys(tableData).map((el, indx) =>

// el === 'img' && tableData[el] !== null ?
// <tr id={el} key={el}>
// <td><img  width='320' height='240' src={`data:image/png;base64,${tableData[el]}`}/></td>
// </tr>
// :
el !== 'img' && el !== 'id' &&
<>
{indx < 2 ?
          <tr id={el} key={el}>
              <th>{el}</th>
              <th>{tableData[el]}</th>
          </tr>
          :
          <tr id={el} key={el}>
          <td>{el}</td>
          <td>{tableData[el]}</td>
      </tr>
}
</>


      
        // <Row key={el}>
        //     <Col>{el}</Col>
        //     <Col>{data[el]}</Col>
        // </Row>
        )}
              </tbody>
        </Table>
      </Row>
    </Container>
  );
};

export default Specifications;