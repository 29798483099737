export function getPump(){
    return {
        id:	'00000000-0000-0000-0000-000000000000',
        deleted: false,
        modelName:'',
        article:'',
        pumpType:null,
        nominalPower:0.0,
        nominalCurrent:0.0,
        availableVoltageDrop:0,
        flowMin:0.0,
        flowMax:0.0,
        weight:0.0,
        bodyMaterialType:null,
        impellerMaterialType:null,
        pipeConnectionType:null,
        inputDiameter:0,
        outputDiameter:0,
        insulationHeatResistanceClass:null,
        enclosureProtectionClass:null,
        rpmLimit:0,
        efficiency:0.0,
        motorEfficiencyClass:null,
        pumpedLiquidTemperature:0,
        workingPressureMax:0.0,
        staticPressure:0.0,
        noiseLevelMax:0.0,
        systemPurposes:	[],
        powerNetworkModes:[]
    }
}