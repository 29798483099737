import React, {useEffect, useState, useRef} from 'react';
import logo from './logo.svg';
import logoPng from './logo.png';
import './App.css';
import {Navbar} from 'react-bootstrap';
import {Nav, NavDropdown, Tab, Tabs } from 'react-bootstrap';
import {Container, Row, Col,  Form, Alert, Badge, Button} from 'react-bootstrap';
import {Api, getData, getPurposes} from './services/apiProvider';
import {Region} from './models/Region';
import LineChart from './LineChart';
import Specifications from './Specifications';
import MultiSelectDropdown from './MultiSelectDropdown';
import TableView from './TableView';
import objectToString from 'object-to-string';
import {FaListOl} from 'react-icons/fa'
import OrderViewModal from './orderView';
import {Hourglass} from 'react-loader-spinner';
import {devicesToView, deviceToSpecification} from './services/deviceConverter';
import { useInterval } from './services/useInterval';

const spinnerStyle = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
const ONE_SECOND = 1000;
const ONE_MINUTE = 60;

function App({isFirst}) {
  const initParams = {"flow":"0","pressure":"0","purposes": [], "staticPressure":"0",}

  const [isFirstLoad, setFirst] = useState(isFirst)
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [needReload, setNeedReload] = useState(false);

  const [regions, setRegions] = useState([])
  const [purposes, setPurposes] = useState([])
  const [devices, setDevices] = useState([])
  const [pumpViews, setPumpViews] = useState([])
  const [pumpSpecs, setPumpSpecs] = useState({})
  const [selectedDevice, setSelected] = useState({})
  const [chartData, setChartData] = useState({charts:[], efficiency:undefined, intersection:[]})
  const [base64Chart, setBase64Chart] = useState([])
  const [target, setTarget] = useState([0,0])

  const [order, setOrder] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const [params, setParams] = useState(initParams)
  const currentUrl = window.location.origin;
  const [defaultOptions,setDefaultOptions] = useState([])
  const [options, setOptions] = useState([]);
  const [timeLeft, setTimeLeft] = useInterval(0, ONE_SECOND);

  const [validationErrors, setValidationErrors] = useState({});

  const [activeTab, setActiveTab] = useState("praph1");
  
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }

  const chartOneRef = useRef();
  const chartTwoRef = useRef();
  const chartThreeRef = useRef();

  useEffect(()=> {

    function fetchData(){
    let opts = []
    purposes.forEach(purp => {opts.push({ value: purp.id, label: purp.name })})
    setOptions(opts)

    const searchParams = new URLSearchParams(window.location.search);
    const flow = Number(searchParams.get('flow') ?? '0');
    const pressure = Number(searchParams.get('pressure') ?? '0');
    const purpose = searchParams.get('purposes');
    const staticPressure = Number(searchParams.get('staticPressure') ?? '0');
    setParams({"flow":flow,"pressure":pressure,"purposes": purpose !== null? purpose.split(',') : [], "staticPressure":staticPressure});
    if (purpose !== null  && purpose.length !==0 && opts.length !==0){
      let newOpts = []
      let asd = purpose.split(',')
      asd.forEach(element => {
        newOpts.push(opts.find(el => el.value === Number(element)))
      });
      setDefaultOptions(newOpts)
      if(params.pressure !== 0){
        setNeedReload(true)
      }
      
    }
  }
  if (purposes.length !==0){
    fetchData()
  }
  },[purposes])

useEffect(() =>{
  async function fetch(){

    const prps = await getPurposes()
    setPurposes(prps)

  }
   fetch()
},[])

useEffect(() =>{
  if (JSON.stringify(params) !== JSON.stringify(initParams)){
    var newParams = objectToString(params, { keySeparator: '=', attrSeparator: '&' })
    var newUrl = currentUrl + "?" + newParams;
    window.history.replaceState(null, null, newUrl);
    if(params.pressure === 0 || (params.purposes.length === 0 || params.purposes[0].length === 0) && !isFirstLoad){
      setFirst(true)
    }
    else if(needReload){
      setNeedReload(false)
      handleSubmit(null)
    }
    
  }
},[params])

useEffect(()=>{
  if(devices.length !== 0){
    setFirst(false)
  }
  setLoading(false);
},[devices])

useEffect(()=>{
  if(base64Chart.length === 3){
    const purps = []
    params.purposes.length !==0 && params.purposes.forEach(purp => purps.push(purposes.find(p => p.id === purp).name) )
    Api.download(selectedDevice.id, selectedDevice.article, chartData.efficiency, params.flow, params.pressure,params.staticPressure,purps,base64Chart,chartData.intersection[0], chartData.intersection[1]).finally(setBase64Chart([]))
  }
},[base64Chart])

async function getDevices(){
  const parms = []
  params.flow !==0 && parms.push(`flow=${encodeURIComponent(params.flow)}`)
  params.pressure !==0 && parms.push(`pressure=${encodeURIComponent(params.pressure)}`)
  params.purposes.length !==0 && params.purposes.forEach(purp => parms.push(`purposes=${encodeURIComponent(purp)}`) )
  params.staticPressure !==0 && parms.push(`staticPressure=${encodeURIComponent(params.staticPressure)}`)
  const paramsStr = parms.join('&')
  const ds = await Api.select(paramsStr)
  setDevices(ds)
  setPumpViews(devicesToView(ds))
  if(ds.length === 0){
    setFirst(true)
    setShow(true)
  }
}

const changeParams = (e) => {
  let par = e.target.id
  let value = e.target.value
  switch(par){
    case "flow":
      setParams({"flow":value,"pressure":params.pressure,"purposes": params.purposes, "staticPressure":params.staticPressure});
      return;
    case "pressure":
      setParams({"flow":params.flow,"pressure":value,"purposes": params.purposes, "staticPressure":params.staticPressure});
      return;
    case "purposes":
       setParams({"flow":params.flow,"pressure":params.pressure,"purposes": value, "staticPressure":params.staticPressure});
       return;
    case "staticPressure":
      setParams({"flow":params.flow,"pressure":params.pressure,"purposes": params.purposes, "staticPressure":value});
      return;
  }
 
}

const changePurpose = (options) => {
  let values = []
  if (options !== undefined && options.length > 0){
    options.forEach((opt) => values.push(opt.value))
    setParams({"flow":params.flow,"pressure":params.pressure,"purposes": values, "staticPressure":params.staticPressure});
    setDefaultOptions(options)
  }else if(options.length === 0){
    setParams({"flow":params.flow,"pressure":params.pressure,"purposes": [], "staticPressure":params.staticPressure});
  }
}

const saveChart = (str) => {
  setBase64Chart([...base64Chart,str])
}

const getChartsB64 = async () => {
  await chartOneRef.current.getAlert();
  await chartTwoRef.current.getAlert();
  await chartThreeRef.current.getAlert();
}

const openCloseOrder = () =>{
  setIsOpen(!isOpen)
}

const chooseClick = () => {
  setLoading(true);
  setFirst(false)
setTimeLeft(ONE_MINUTE)
  getDevices()

}

const handleSubmit = (e) => {
  e !== null && e.preventDefault();

  // Проверяем, что обязательные поля не пусты
  const errors = {};
  if (params.flow === 0) {
    errors.flow = 'Обязательное поле';
  }
  if (params.pressure === 0) {
    errors.pressure = 'Обязательное поле';
  }
  if (params.purposes && (params.purposes.includes(0) || params.purposes.includes(1)) && !params.staticPressure) {
    errors.staticPressure = 'Обязательное поле';
  }

  // Если есть ошибки валидации, обновляем состояние
  if (Object.keys(errors).length > 0) {
    setValidationErrors(errors);
    return;
  }

  // Ваш код для обработки отправки формы
  setValidationErrors({})
  chooseClick();
  setTarget([params.flow, params.pressure])
};

// const getChart = async (id)=>{
//   let chart = await Api.charts(id,params.pressure,params.flow, params.staticPressure)
//   setChartData(chart)
// }

const deviceSelectHandler = async (id) => {
  let chart = await Api.charts(id,params.pressure,params.flow, params.staticPressure)
  setChartData(chart)
  let device = devices.find(el => el.id === id)
    setPumpSpecs(deviceToSpecification(device, chart.efficiency, chart.intersection))
    setSelected(device)
}

const paramsContainer = () => {

  return  <Container className='mb-2'>
    <Form>
      {/* <Row> */}
      <Form.Group as={Row} controlId="flow">
        <Form.Label column lg={6} md={6} xs={7} sm={7}>Расход, м<sup>3</sup>/ч</Form.Label>
        <Col lg={{span:5, offset:1}} md={{span:5, offset:1}} xs={{span:4, offset:1}} sm={{span:4, offset:1}}>
        <Form.Control
          type="number"
          placeholder="0"
          name="flow"
          value={params.flow}
          onChange={(e) =>changeParams(e)}
        />
        </Col>
        {validationErrors.flow && <Alert variant="danger">{validationErrors.flow}</Alert>}
      </Form.Group>
      <br />
      {/* </Row>
      <Row> */}
      <Form.Group as={Row} controlId="pressure">
        <Form.Label column lg={6} md={6} xs={7} sm={7}>Напор, м</Form.Label>
        <Col lg={{span:5, offset:1}} md={{span:5, offset:1}} xs={{span:4, offset:1}} sm={{span:4, offset:1}}>
        <Form.Control
          type="number"
          placeholder="0"
          name="pressure"
          value={params.pressure}
          onChange={(e) =>changeParams(e)}
        /></Col>
        {validationErrors.pressure && <Alert variant="danger">{validationErrors.pressure}</Alert>}
      </Form.Group>
      <br />
        <Form.Group as={Row} controlId="staticPressure">
          <Form.Label column lg={6} md={6} xs={7} sm={7}> Статическая высота напора, м</Form.Label>
          <Col lg={{span:5, offset:1}} md={{span:5, offset:1}} xs={{span:4, offset:1}} sm={{span:4, offset:1}}>
          <Form.Control
            type="number"
            placeholder="0"
            name="staticPressure"
            value={params.staticPressure}
            onChange={(e) =>changeParams(e)}
          />
          </Col>
          {validationErrors.staticPressure && <Alert variant="danger">{validationErrors.staticPressure}</Alert>}
        </Form.Group>
        <br />
        <Form.Group as={Row} controlId="pressure">
           <Form.Label  column lg={6} md={6} xs={7} sm={7}>Назначение системы</Form.Label>
            <Col lg={{span:6, offset:0}} md={{span:6, offset:0}} xs={{span:5, offset:0}} sm={{span:5, offset:0}}>
              <MultiSelectDropdown handleSelect={changePurpose} options={options}  defaultOpts={defaultOptions} />
            </Col>
      </Form.Group>
      <br />
        {/* </Row> */}
      <Button variant="primary" type="submit" onClick={handleSubmit}>
        Подобрать
      </Button>
    </Form>
  </Container>
}
  return (
    <div className="App">
      <OrderViewModal order={order} isOpen={isOpen} onClose={openCloseOrder}/>
      <Navbar className='mb-2 xs-2 sm-2' bg="light" data-bs-theme="light">
        <Container>
        <Navbar.Brand href="https://pulsarm.ru/">
        <img
              src={logoPng}
              width="130"
              height="50"
              className="d-inline-block align-top"
              alt="Pulsar"
            />
        </Navbar.Brand>
          {/* <Nav className="me-auto">
            <Nav.Link href="https://pulsarm.ru/">Pulsar</Nav.Link>
            <NavDropdown title="Регион" id='region-dropdown'>
              {regions.map(reg =>
              <NavDropdown.Item id={reg.id} onChange={(e) =>changeParams(e)}>{reg.name}</NavDropdown.Item>
              )}
            </NavDropdown>
          </Nav> */}
          {/* <Nav.Item id='orderViewButton' onClick={openCloseOrder}><FaListOl/>
          {order.length > 0 && <Badge variant="danger">{order.length}</Badge>}
          </Nav.Item> */}
        </Container>
      </Navbar>
      <Container className='Main-Container'>
        {isFirstLoad ?
        <>
        <Row><Col md={{ span: 6, offset: 3 }}  xs={{ span: 12, offset: 1 }} sm={{ span: 12, offset: 1 }}>{paramsContainer()}</Col>
        </Row>
        <br/>
        {show &&
          <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>Для заданных параметров насосов не найдено!</Alert.Heading>
          <p>
           Попробуйте ввести другие параметры и повторить поиск.
          </p>
        </Alert>
}
        </>
         :
         loading ?
         timeLeft > 0 ?
         <div style={spinnerStyle}>
          <Hourglass
           visible={true}
           height="140"
           width="140"
           ariaLabel="hourglass-loading"
           wrapperStyle={{}}
           wrapperClass=""
           colors={['#306cce', '#72a1ed']}
         />
         </div>
         : <>{setFirst(true)}</>
         :
        <Row className='mb-5'>
          <Col xs={12} sm={12} md={12} lg={4}>
          <Row id='upperRow'>
            <Col >
            {paramsContainer()}
            </Col>
            {/* <Col xs={12} sm={12} md={12} lg={7}>
        </Col> */}
          </Row>
          <Row id='lowerRow'>
          <Col> 
         <TableView data={pumpViews} setSelection={deviceSelectHandler}/>
          </Col>
          </Row> 
          </Col>
          <Col id='chartRow' xs={12} sm={12} md={12} lg={4}>
          <Container className="mb-3 md-12 xs-12">
     {/* <Tabs
      // defaultActiveKey="praph1"
      id="uncontrolled-tab-example"
      className="mb-3 md-12 xs-12"
      activeKey={activeTab} onSelect={handleTabChange}
>*/}
      {/* <Tab eventKey="praph1" title="Напор/Характеристика системы"> */}
      <LineChart inData={[chartData.charts[0],chartData.charts[3]]} chartLabels={["Напор","Характеристика системы"]} saveChart={saveChart} target={target} intersection={chartData.intersection} ref={chartOneRef} />
      {/* </Tab> */}
      {/* <Tab eventKey="praph2" title="Мощность"> */}
      <LineChart inData={[chartData.charts[1]]} chartLabels={["Мощность"]} saveChart={saveChart} ref={chartTwoRef}/>
      {/* </Tab> */}
      {/* <Tab eventKey="praph3" title="Npsh"> */}
      <LineChart inData={[chartData.charts[2]]} chartLabels={["NPSH"]} saveChart={saveChart} ref={chartThreeRef}/>
      {/* </Tab> */}
      {/*<Tab eventKey="praph4" title="Характеристика системы">
      <LineChart inData={chartData[3]} chartLabel={"Характеристика системы"} />
        </Tab>*/}
    {/* </Tabs> */}
        </Container>
          </Col>
          <Col className="d-flex align-items-stretch">
          <Container>
            <Specifications data={pumpSpecs}/>
            <br/>
            <Button type='primary' onClick={getChartsB64}>Скачать</Button>
          </Container>
          </Col>
        </Row>
 }
      </Container>

    </div>
  );
}

export default App;
