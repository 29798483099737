import React, { useState } from 'react';
import {Table}from 'react-bootstrap';
import QuantitySelector from './QuantitySelector';
import { Modal } from 'react-bootstrap';

const OrderViewModal = ({ order, isOpen, onClose }) => {

  const [orderDetails, setOrderDetails] = useState(order)

  if (!isOpen) return null;

  // const modalStyle = {
  //   position: 'absolute',
  //   top: target.offsetTop + target.offsetHeight + 10,
  //   left: target.offsetLeft,
  // };

  const openData = (pumpId)=> {
    console.log(pumpId)
  }

  const addToOrder = (pumpId)=> {
    console.log(pumpId)
  }

  return (
    <Modal size="lg" show={isOpen} onHide={onClose} centered={false} className="orderViewModal">
      <Modal.Header closeButton>
      <Modal.Title>Состав заказа</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Table>
            <thead>
                <tr>
                    <th>Код</th>
                    <th>Наименование</th>
                    <th>Расход</th>
                    <th>Напор</th>
                    <th>Подробнее</th>
                    <th>Количество</th>
                    <th>Удалить</th>
                </tr>
            </thead>
            <tbody>
                {orderDetails.map(row =>
                <tr id={row.id} key={row.id}>
                    <td>{row.code}</td>
                    <td>{row.name}</td>
                    <td>{row.expenditure}</td>
                    <td>{row.pressure}</td>
                    <td><button className='btn btn-link' id={row.id} onClick={()=>openData(row.id)}>Открыть</button></td>
                    <td><QuantitySelector/></td>
                    <td><button className='btn btn-secondary' id={row.id} onClick={()=>addToOrder(row.id)}>Удалить</button></td>
                </tr>
                 )}
            </tbody>
      </Table>
      </Modal.Body>
      <Modal.Footer>
      <button className='btn btn-primary' onClick={onClose}>Оформить</button>
      <button className='btn btn-warning' onClick={onClose}>Закрыть</button>
      </Modal.Footer>
    </Modal>
  );
};
export default OrderViewModal;