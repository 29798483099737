import React, { useState, useEffect } from 'react';
import {Container, Form }from 'react-bootstrap';
import { Modal, Row, Col, Tab, Tabs, Alert } from 'react-bootstrap';

const DeleteConfirmModal = ({dict, isOpen, onClose, appyMethod }) => {


  useEffect(()=>{

  },[dict])


  if (!isOpen) return null;

  return (
    <Modal show={isOpen} onHide={onClose} backdrop='static' centered={false} className="EditDictModal">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
      <Form>
      <Row className="mb-3">
        <Form.Group as={Row} controlId="name">
          <Form.Label>Запись {dict.name} будет окончательно удалена. Подтвердить?</Form.Label>
        </Form.Group>
    </Row>
    </Form>
      </Modal.Body>
      <Modal.Footer>
      <button className='btn btn-danger' onClick={()=>appyMethod(dict)}>Удалить</button>
      <button className='btn btn-warning' onClick={onClose}>Отмена</button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteConfirmModal;