import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import logoPng from './logo.png';
import './App.css';
import {Container, Row, Col,  Table, Button, Pagination, Form} from 'react-bootstrap';
import { Modal, Tab, Tabs, Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Api, getData} from './services/apiProvider';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import objectToString from 'object-to-string';
import {FaSignOutAlt} from 'react-icons/fa';
import {Hourglass} from 'react-loader-spinner';
import {devicesToView, deviceToSpecification, getNames, devicesToAdminView} from './services/deviceConverter';
import EditDictModal from './EditDictModal';
import DeleteConfirmModal from './DeleteConfirmModal';
import { getPump } from './models/Device';
import { getDictionaryRecord } from './models/DictionaryRecord';
import { useInterval } from './services/useInterval';
import {dictNames} from './services/constants';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60;
const animatedComponents = makeAnimated();

function DictTab() {
  const [loading, setLoading] = useState(true);
  const [selectedDict,setSelectedDict] = useState(dictNames[0])
  // const [purposes, setPurposes] = useState([])
  const [tableData, setTableData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [objToEdit, setEditObj] = useState(null)

  const [objToDelete, setDelete] = useState(null)
  const [isNeedConfirm, setNeedConfirm] = useState(false)

  const [timeLeft, setTimeLeft] = useInterval(ONE_MINUTE, ONE_SECOND);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectOptions, setOptions] = useState([])


  const spinnerStyle = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

const toOpt = (dict) => {
    let opts = []
    if (dict)
        dict.forEach(el => {opts.push({ value: el[0], label: el[1] })})
    return opts
  }

  const getDict = async () => {
    const dct = await Api.dictionary(selectedDict[0])
    if(dct){
        setTableData(dct)
        setLoading(false)
    }
    
  }

  useEffect(()=>{

    function fetchData(){
        getDict()
   }
  if (selectedDict){
    fetchData()
  }
  },[selectedDict])

  const tryReload = () => {
    getDict()
    setTimeLeft(ONE_MINUTE)
  }
  

useEffect(() =>{
  async function fetch(){
    setOptions(toOpt(dictNames))

    //loadData()
    getDict()

  }
   fetch()
},[])

  const openCloseEdit = (d = null, isN = false) =>{
    setIsNew(isN)
    const dict = !isN ? d : getDictionaryRecord()
    setEditObj(dict)
    setIsOpen(!isOpen)
    setValidationErrors({})
  }

  const closeConfirm = ()=>{
    setDelete(null);
    setNeedConfirm(false);
  }

  const changeSelect = (val) => {
    // const dict = data[name].find(el=> el.id === val.value)
    setSelectedDict(dictNames.find(el=>el[0] === val.value));
  }


  const  deleteDict = async (dict) =>{
    await Api.deleteDictionary(selectedDict[0], dict)
    getDict()
    closeConfirm()
  }

  const applyEdit = async (dict) =>{

    const errors = {};

    if (!dict.name) {
      errors.name = 'Обязательное поле';
    }
    // Если есть ошибки валидации, обновляем состояние
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
  
    setValidationErrors({})
    if(isNew){
        const newDict = {name: dict.name, description: dict.description}
      await Api.addDictionary(selectedDict[0], newDict)
    }else{
      await Api.editDictionary(selectedDict[0], dict)
    }
    setIsOpen(!isOpen)
    setEditObj(getDictionaryRecord())
    getDict()
  }

const signOut = () =>{
  
}
const headerSortingStyle = { backgroundColor: '#D3D3D3' };

const columns = [{
  dataField: 'id',
  text: 'id',
  style: {'width':'5%'},
  sort: true,
  headerSortingStyle
}, {
  dataField: 'name',
  text: 'Имя',
  style: {'width':'30%'},
  sort: true,
  headerSortingStyle
},{
  dataField: 'description',
  text: 'Описание',
  sort: true,
  headerSortingStyle
},
{
  dataField: 'df1',
  isDummyField: true,
  text: 'actions',
  style: {'width':'20%'},
  formatter: (cellContent, row) => {
    return (
      <>
      <Button variant="primary" size="sm" onClick={()=>openCloseEdit(row)}>
        Редактировать
      </Button>{' '}
      <Button variant="warning" size="sm" onClick={()=>{setDelete(row);setNeedConfirm(true)}}>
        Удалить
      </Button>
    </>
    );
  }
}
];

const defaultSorted = [{
  dataField: 'id',
  order: 'asc'
}];

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
     { from } - { to } из { size }
  </span>
);

const options = {
  paginationSize: 5,
  pageStartIndex: 1,
  hidePageListOnlyOnePage: true,
  firstPageText: '|<',
  prePageText: '<',
  nextPageText: '>',
  lastPageText: '>|',
  nextPageTitle: 'Следующая страница',
  prePageTitle: 'Предыдущая страница',
  firstPageTitle: 'Первая страница',
  lastPageTitle: 'Последняя страница',
  showTotal: true,
  paginationTotalRenderer: customTotal,
  disablePageTitle: true,
  sizePerPageList: [{
    text: '10', value: 10
  }, {
    text: '20', value: 20
  }]
};



  return (
    <div className="DictTab">
      <EditDictModal dict={objToEdit} isOpen={isOpen} validErrors={validationErrors} onClose={openCloseEdit} appyMethod={applyEdit}/>
      <DeleteConfirmModal dict={objToDelete} isOpen={isNeedConfirm} onClose={closeConfirm} appyMethod={deleteDict}/>
        { loading ?
        timeLeft > 0 ?
        <div style={spinnerStyle}>
         <Hourglass
          visible={true}
          height="140"
          width="140"
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={['#306cce', '#72a1ed']}
        />
        </div>
        : <div style={spinnerStyle}> <p>Не удалось подключиться к серверу</p>
          <Button onClick={()=>tryReload()}>Повторить попытку</Button>
        </div>
         :
  <Container className='Dict-Container'>
        <Row className="mb-3">
            <Col xs={2} md={2}><Button onClick={()=>openCloseEdit(null,true)}>Добавить</Button></Col>
            <Form.Group as={Col} controlId="pumpType">
        <Select className='dict_select'
      value={selectOptions.find(el=> el.value === selectedDict[0])}
      closeMenuOnSelect={true}
      components={animatedComponents}
      onChange={(newValue)=>changeSelect(newValue)}
      options={selectOptions}
    />
    </Form.Group>
        </Row>
        <Row>
          {tableData.length !== 0 &&
        <BootstrapTable keyField='id' data={ tableData } columns={ columns } pagination={ paginationFactory(options)} defaultSorted={defaultSorted} />
          }
    </Row>
      </Container>
  }
    </div>
  );
}

export default DictTab;
