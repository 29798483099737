const deviceToView = (device) => {
    return {id: device.id, code: device.article, name: device.modelName}
}

export const getNames = (elems) => {
   // var res = ''
    const newArr = elems.map(el => el.name)
    return newArr.join(', ')
    // elems.forEach(element => {
    //     res += element.name + ', '
    // });
    //return res
}

const deviceToAdminView = (device) => {
    return {id: device.id, modelName: device.modelName, systemPurposes:  getNames(device.systemPurposes), article: device.article, nominalPower: device.nominalPower, flowMin: device.flowMin, flowMax: device.flowMax}
}

export const devicesToView = (devices) => {
    const views = []
    devices.forEach(element => {
        views.push(deviceToView(element))
    })
    return views
}

export const devicesToAdminView = (devices) => {
    const views = []
    devices.forEach(element => {
        views.push(deviceToAdminView(element))
    })
    return views
}

export const deviceToSpecification = (device, effic, intersection) => {
    return {
        'Расчетный расход, м³/ч': (intersection[0]).toFixed(2),
        'Расчетный напор, м': (intersection[1]).toFixed(2),
        'id': device.id,
        'img': device.imageBase64,
        'Название модели': device.modelName,
        'Назнаначение системы': getNames(device.systemPurposes),
        'Артикул': device.article,
        'Тип насоса': device.pumpType.name,
        'Номинальная мощность, кВт': device.nominalPower,
        'Номинальный ток, А': device.nominalCurrent,
        'Допустимое падение напряжения, %': device.availableVoltageDrop,
        'Расход минимальный, м³/ч': device.flowMin,
        'Расход максимальный, м³/ч': device.flowMax,
        'Вес, кг': device.weight,
        'Материал корпуса насоса': device.bodyMaterialType.name,
        'Материал рабочего колеса': device.impellerMaterialType.name,
        'Тип присоединения к трубопроводу': device.pipeConnectionType.name,
        'Диаметр патрубка на всасывающей стороне, мм': device.inputDiameter,
        'Диаметр патрубка на напорной стороне, мм': device.outputDiameter,
        'Класс нагревостойкости изоляции': device.insulationHeatResistanceClass.name,
        'Степень защиты': device.enclosureProtectionClass.name,
        'Максимальная частота вращения об/мин': device.rpmLimit,
        'КПД': effic !== undefined ? effic.toFixed(2) : "",
        'Класс эффективности мотора': device.motorEfficiencyClass.name,
        'Максимальная температура перекачиваемой среды, °С': device.pumpedLiquidTemperature,
        'Максимальное рабочее давление, бар': device.workingPressureMax,
        // 'Статическая высота напора, м': device.staticPressure,
        'Максимальный уровень шума, дБа': device.noiseLevelMax,
        'Напряжение питания': getNames(device.powerNetworkModes)
    }
}