import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const MultiSelectDropdown = ({handleSelect, options, defaultOpts, name}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  // useImperativeHandle(ref, ()=>({setDefault }));
  useEffect(()=>{
    defaultOpts.length !== 0 && handleChange(defaultOpts)
  },[defaultOpts])

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    if (name !== undefined){
      handleSelect(selectedValues,name);
    }else{
      handleSelect(selectedValues);
    }
    
  };

  return (
    <Select
      value={selectedOptions}
      closeMenuOnSelect={false}
      components={animatedComponents}
      onChange={handleChange}
      isMulti
      options={options}
      placeholder={'Укажите...'}
    />
  );
};

export default MultiSelectDropdown;